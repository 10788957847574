import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      {/* embed */}
      <iframe
        class="page-embed"
        src="https://r-e-a-l.it/capture/assets/"
      ></iframe>

      {/* <div className="options">
        <div
          className="option active"
          style={{
            backgroundImage:
              "url('https://66.media.tumblr.com/6fb397d822f4f9f4596dff2085b18f2e/tumblr_nzsvb4p6xS1qho82wo1_1280.jpg')",
          }}
        >
          <div className="shadow"></div>
          <div className="label">
            <div className="icon">R</div>
            <div className="info">
              <div className="main">Robotic</div>
              <div className="sub">Robotic Reality Capture</div>
            </div>
          </div>
        </div>
        <div
          className="option"
          style={{
            backgroundImage:
              "url('https://66.media.tumblr.com/8b69cdde47aa952e4176b4200052abf4/tumblr_o51p7mFFF21qho82wo1_1280.jpg')",
          }}
        >
          <div className="shadow"></div>
          <div className="label">
            <div className="icon">E</div>
            <div className="info">
              <div className="main">Efficient</div>
              <div className="sub">Efficient Reality Capture</div>
            </div>
          </div>
        </div>
        <div
          className="option"
          style={{
            backgroundImage:
              "url('https://66.media.tumblr.com/5af3f8303456e376ceda1517553ba786/tumblr_o4986gakjh1qho82wo1_1280.jpg')",
          }}
        >
          <div className="shadow"></div>
          <div className="label">
            <div className="icon">A</div>
            <div className="info">
              <div className="main">Aerial</div>
              <div className="sub">Aerial Reality Capture</div>
            </div>
          </div>
        </div>
        <div
          className="option"
          style={{
            backgroundImage:
              "url('https://66.media.tumblr.com/5516a22e0cdacaa85311ec3f8fd1e9ef/tumblr_o45jwvdsL11qho82wo1_1280.jpg')",
          }}
        >
          <div className="shadow"></div>
          <div className="label">
            <div className="icon">L</div>
            <div className="info">
              <div className="main">Localized</div>
              <div className="sub">Localized Reality Capture</div>
            </div>
          </div>
        </div>
        <div
          className="option"
          style={{
            backgroundImage:
              "url('https://66.media.tumblr.com/f19901f50b79604839ca761cd6d74748/tumblr_o65rohhkQL1qho82wo1_1280.jpg')",
          }}
        >
          <div className="shadow"></div>
          <div className="label">
            <div className="icon">i</div>
            <div className="info">
              <div className="main">iMaging</div>
              <div className="sub">iMaging Reality Capture</div>
            </div>
          </div>
        </div>
        <div
          className="option"
          style={{
            backgroundImage:
              "url('https://66.media.tumblr.com/8b69cdde47aa952e4176b4200052abf4/tumblr_o51p7mFFF21qho82wo1_1280.jpg')",
          }}
        >
          <div className="shadow"></div>
          <div className="label">
            <div className="icon">T</div>
            <div className="info">
              <div className="main">Terrestrial</div>
              <div className="sub">Terrestrial Reality Capture</div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
