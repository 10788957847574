/* libraries */
import React, { Component, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
/* components */
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

/* specialties */
import Home from "./templates/home/Home";
import Coming from "./templates/coming/Coming";
import Support from "./templates/services/support/Support";
import Coaching from "./templates/services/coaching/Coaching";

/* templates */
import Error from "./templates/error/Error";
import Contact from "./templates/contact/Contact";
import Product from "./templates/product/Product";
import Product2 from "./templates/product2/Product";
import Software from "./templates/software/Software";
import Industries from "./templates/industries/Industries";
import { API_URL, isProduction } from './config/config'
import routesData from './routes.json';

const App = () => {
  const [routes] = useState(routesData.routes);

  const getRoute = () => {
    if(isProduction) {
      return null;
    } else {
      axios.get(`${API_URL}/routes`).then(response => {
        console.log(response)
      });
    }
  };

  useEffect(() => {
    getRoute();
  }, []);

  return (
    <BrowserRouter>
      <div id="spacer"></div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        {routes.map(item => {
          const path = item.filename.split(".")[0].toLowerCase();
          
          if (item.dir_name === "products") {
            return (
              <Route
                path={`/${item.dir_name}/${path}`}
                element={<Product content= {import ( `./content/${item.dir_name}/${item.filename}` )} />}
              />
            );
          }

          if (item.dir_name === "industries") {
            return (
              <Route
                path={`/${item.dir_name}/${path}`}
                element={<Industries content= {import ( `./content/${item.dir_name}/${item.filename}` )} />}
              />
            );
          }

          if (item.dir_name === "software") {
            return (
              <Route
                path={`/${item.dir_name}/${path}`}
                element={<Software content= {import ( `./content/${item.dir_name}/${item.filename}` )} />}
              />
            );
          }
           
        })}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
