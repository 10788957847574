import React from "react";
import "./Product.css";
import Contact from "../contact/Contact";

var domify = function (str) {
  var dom = document.createElement("p");
  dom.innerHTML = str;
  return dom;
};
class Product extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props.content;
    return (
      <div>
        <section id="landing">
          <h1 className="land-header ml3">{props.landing.productName}</h1>
          <iframe
            className="embed-responsive-item"
            src={props.landing.videoLink}
            allow="autoplay;"
            allowFullScreen=""
            id="vm-de75ef11-5059-44a9-9151-6a98f844d2b3"
            data-ready="true"
          ></iframe>
          <hr style={{ width: "75%", border: "1px solid #333" }} />
        </section>

        <section
          id="advertise"
          style={{
            backgroundImage: `linear-gradient(#000000, ${props.sketchfab.gradientColor})`,
          }}
        >
          <div id="overview"></div>
          <h1 style={{ zIndex: "5" }}>
            {/* {props.advertise.title} (domify function isn't returning an error) */}
            The{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              power
            </span>
            <br />
            of{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              reality capture
            </span>{" "}
            technology
            <br />
            in the{" "}
            <span
              className="gradient-text"
              style={{
                backgroundImage: "linear-gradient(30deg, #24272A, #db0d0d)",
              }}
            >
              palm
            </span>{" "}
            of your hand.
          </h1>
          <iframe
            style={{ zIndex: "5" }}
            src={props.advertise.videoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p style={{ textAlign: "center", color: "#888", zIndex: "5" }}>
            {props.advertise.paragraph}
          </p>
        </section>

        <section id="sketchfab">
          <iframe
            loading="lazy"
            width="100%"
            height="100%"
            src={props.sketchfab.modelLink}
          ></iframe>
          <hr />
        </section>

        <section
          id="presenting"
          style={{
            backgroundImage: `linear-gradient(${props.sketchfab.gradientColor}, #000000)`,
          }}
        >
          <div className="vidwrap">
            <div className="vidleft">
              <h1
                className="gradient-text"
                style={{
                  backgroundImage: "linear-gradient(120deg, #24272A, #db0d0d)",
                }}
              >
                {props.videoSections[0].title}
              </h1>
              <hr
                style={{
                  width: "100px",
                  float: "left",
                  border: "1px solid #333",
                }}
              />{" "}
              <br />
              <br />
              <p>{props.videoSections[0].paragraph}</p>
            </div>
            <div className="midline"></div>
            <div className="vidright">
              <iframe
                className="yt-vid"
                src={props.videoSections[0].video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>

        <section id="using">
          <canvas className="hexagon-trail"></canvas>
          <div className="vidwrap" style={{ zIndex: "5" }}>
            <div className="vidleft vid-order-hook">
              <iframe
                className="yt-vid"
                src={props.videoSections[0].video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="midline"></div>
            <div className="vidright text-order-hook">
              <h1
                className="gradient-text"
                style={{
                  backgroundImage: "linear-gradient(120deg, #24272A, #db0d0d)",
                }}
              >
                {props.videoSections[1].title}
              </h1>
              <hr
                style={{
                  width: "100px",
                  float: "left",
                  border: "1px solid #333",
                }}
              />
              <br />
              <br />
              <p>{props.videoSections[1].paragraph}</p>
            </div>
          </div>
        </section>

        <section id="demonstrating">
          <div className="vidwrap">
            <div className="vidleft">
              <h1
                className="gradient-text"
                style={{
                  backgroundImage: "linear-gradient(120deg, #24272A, #db0d0d)",
                }}
              >
                {props.videoSections[2].title}
              </h1>
              <hr
                style={{
                  width: "100px",
                  float: "left",
                  border: "1px solid #333",
                }}
              />
              <br />
              <br />
              <p>{props.videoSections[2].paragraph}</p>
            </div>
            <div className="midline"></div>
            <div className="vidright">
              <iframe
                className="yt-vid"
                src={props.videoSections[2].video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>

        <section id="unboxing" style={{ display: `${props.isUnboxing}` }}>
          <div className="vidwrap" style={{ zIndex: "5" }}>
            <div className="vidleft vid-order-hook">
              <iframe
                className="yt-vid"
                src={props.videoSections[3].video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="midline"></div>
            <div className="vidright text-order-hook">
              <h1
                className="gradient-text"
                style={{
                  backgroundImage: "linear-gradient(120deg, #24272A, #db0d0d)",
                }}
              >
                {props.videoSections[3].title}
              </h1>
              <hr
                style={{
                  width: "100px",
                  float: "left",
                  border: "1px solid #333",
                }}
              />
              <br />
              <br />
              <p>{props.videoSections[3].paragraph}</p>
            </div>
          </div>
        </section>
        <section
          className="specifications section"
          id="specifications"
          style={{ marginTop: 0, borderTop: "1px solid #701109" }}
        >
          <h1
            style={{
              position: "absolute",
              zIndex: "5",
              width: "100vw",
              textAlign: "center",
              paddingTop: "10vh",
              fontSize: "5em",
            }}
          >
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
            {props.statistics.specsTitle}
            <hr
              style={{
                width: "50px",
                border: "1px solid #db0d0d",
                zIndex: "5",
              }}
            />
          </h1>
          <video
            id="background-video"
            autoPlay
            loop
            muted
            style={{ width: "100vw" }}
          >
            <source src={props.statistics.specsVideo} type="video/mp4" />
          </video>
        </section>
        <section id="statistics">
          <div className="stats-above">
            <span>Specifications</span>
            <h3>{props.statistics.smallTitle}</h3>
            <hr />
            <br />
          </div>
          <div className="stats-container">
            <div className="stats-column">
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[0].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[0].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[1].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[1].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[2].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[2].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[3].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[3].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
            </div>
            <div className="stats-column">
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[4].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[4].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[5].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[5].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[6].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[6].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
              <li>
                <h4 className="specs-title">
                  {props.statistics.statsList[7].above}
                </h4>
                <span className="specs-info">
                  {props.statistics.statsList[7].under}
                </span>
                <br />
                <hr className="specs-under" />
                <br />
              </li>
            </div>
            <div className="stats-column" id="stats-column-image">
              <img
                src={props.statistics.statsImage}
                alt="BLK2GO measurements and dimensions"
              />
            </div>
          </div>
          <hr className="stats-under" />
        </section>
        <section id="datasets" style={{ marginTop: "100px" }}>
          <div>
            <h1>Download the spec sheet.</h1>
            <div>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open("mailto:experts@r-e-a-l.it", "_blank");
                }}
                style={{ marginRight: "15px" }}
              >
                <span>
                  <span>Contact</span>
                </span>
              </button>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open(window.open(props.datasets[1], "_blank"));
                }}
              >
                <span>
                  <span>Download</span>
                </span>
              </button>
            </div>
          </div>
          <div>
            <h1>Download the datasets.</h1>
            <div>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open("mailto:experts@r-e-a-l.it", "_blank");
                }}
                style={{ marginRight: "15px" }}
              >
                <span>
                  <span>Contact</span>
                </span>
              </button>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.open(window.open(props.datasets[1], "_blank"));
                }}
              >
                <span>
                  <span>Download</span>
                </span>
              </button>
            </div>
          </div>
          <hr className="stats-under" />
        </section>
        <Contact displayGradient={false} />

        <section id="ordering">
          <div className="order-options">
            <div className="ordering-column" id="order-border">
              <h1>{props.ordering[0].title}</h1>
              <p>{props.ordering[0].paragraph}</p>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.location.href =
                    "mailto:experts@r-e-a-l.it?subject=More%20info%20about%20BLK2GO&body=Hello,%20I'm%20looking%20for%20more%20information%20about%20the%20BLK2GO.";
                }}
              >
                <span>
                  <span>{props.ordering[0].buttonText}</span>
                </span>
              </button>
            </div>
            <div className="ordering-column">
              <h1>{props.ordering[1].title}</h1>
              <p>{props.ordering[1].paragraph}</p>
              <button
                className="button button--hyperion"
                onClick={() => {
                  window.location.href =
                    "mailto:experts@r-e-a-l.it?subject=Quote%20for%20BLK2GO&body=Hello,%20please%20send%20me%20a%20quote%20for%20the%20BLK2GO.";
                }}
              >
                <span>
                  <span>{props.ordering[1].buttonText}</span>
                </span>
              </button>
            </div>
          </div>
          <div className="ordering-below">
            <img src="https://r-e-a-l.it/capture/images/etcetera/capture-reality.gif" />
          </div>
        </section>
      </div>
    );
  }
}

export default Product;
