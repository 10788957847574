import React from "react";
import "./Contact.css";
// import contactImg from "./Contact.png";

const Contact = ({ props }) => {
  return (
    <div id="contact">
      {() => {
        if (props.displayGradient) {
          <img
            src={"https://csgroup.ca/wp-content/uploads/2020/08/opt.png"}
            alt="Globe of point clouds"
          />;
        }
      }}
      {() => {
        if (props.displayGradient) {
          <div id="contact-gradient" />;
        }
      }}
      {/* <div className="overlay">
        <h1>Contact Us</h1>
      </div> */}
      <div className="contact-title">
        <h1>Contact Us.</h1>
        <hr />
        <br />
        <br />
      </div>
      <div className="contact-info">
        <div className="contact-row mail-phone" id="contact-left">
          <div id="email">
            {/* <span className="digit">1.</span> */}
            <a href="mailto:experts@r-e-a-l.it">
              <i class="fa-solid fa-envelope"></i> experts@r-e-a-l.
              <span className="i">i</span>t
            </a>
          </div>
          <div id="phone">
            <a href="callto:+18445732548">
              <i class="fa-solid fa-phone"></i> +1-844-5-r-e-a-l.
              <span className="i">i</span>t
            </a>
          </div>
        </div>
        <div className="contact-row social-media" id="contact-right">
          <div id="linkedin">
            <a href="#">
              <i className="fab fa-linkedin"></i> LinkedIn
            </a>
          </div>
          <div id="facebook">
            <a href="#">
              <i className="fab fa-facebook-square"></i> Facebook
            </a>
          </div>
          <div id="instagram">
            <a href="#">
              <i className="fab fa-instagram-square"></i> Instagram
            </a>
          </div>
          <div id="twitter">
            <a href="#">
              <i className="fab fa-twitter-square"></i> Twitter
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
