import React from "react";

class Features extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 1,
      list: props.array,
    };
  }

  render() {
    const features = this.state.list.map((i) => {
      return (
        <section className="technology section tile">
          <div className="left-section text-section">
            <div className="section-text-outer">
              <h1>{i.title}</h1>
              <p>{i.paragraph}</p>
            </div>
          </div>
          <div className="right-section">
            <img
              src={i.image}
              alt="BLK2FLY by a building"
              style={{ opacity: "0.5" }}
            ></img>
          </div>
        </section>
      );
    });
    return features;
  }
  //   videoInternal = (array, item) => {
  //     //   if (this.state.count === 1) {
  //     //   this.setState({ count: 2 });
  //     return (
  //       <section className="technology section tile">
  //         <div className="left-section text-section">
  //           <div className="section-text-outer">
  //             <h1>{array[item].title}</h1>
  //             <p>{array[item].paragraph}</p>
  //           </div>
  //         </div>
  //         <div className="right-section">
  //           <img
  //             src={array[item].image}
  //             alt="BLK2FLY by a building"
  //             style={{ opacity: "0.5" }}
  //           ></img>
  //         </div>
  //       </section>
  //     );
  //   };

  //   render() {
  //     const videoContainer = this.props.array.map((i) => {
  //       this.videoInternal(this.props.array, i);
  //     });
  //     return { videoContainer };
  //   }
}

export default Features;
