import React from "react";

class SpecsItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 1,
      list: props.array,
    };
  }

  render() {
    const specsItem = this.state.list.map((i) => {
      return (
        <li style={{ listStyle: "none" }}>
          <h4 className="specs-title">{i.above}</h4>
          <span
            className="specs-info"
            style={{
              fontFamily: "'Source Sans Pro', sans-serif",
            }}
          >
            {i.under}
          </span>
          <br />
          <hr className="specs-under" />
          <br />
        </li>
      );
    });
    return specsItem;
  }
}

export default SpecsItem;
