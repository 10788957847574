import React from "react";
import "./Footer.css";

import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-column">
        <h1>solutions</h1>
        <ul>
          <li>
            <a href="#">equipment</a>
          </li>
          <li>
            <a href="#">software</a>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <h1>services</h1>
        <ul>
          <li>
            <a href="#">education</a>
          </li>
          <li>
            <a href="#">support</a>
          </li>
          <li>
            <a href="#">coaching</a>
          </li>
          <li>
            <a href="#">help</a>
          </li>
          <li>
            <a href="#">rentals</a>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <h1>industries</h1>
        <ul>
          <li>
            <a href="#">survey &amp; mapping</a>
          </li>
          <li>
            <a href="#">construction</a>
          </li>
          <li>
            <a href="#">manufacturing</a>
          </li>
          <li>
            <a href="#">public safety</a>
          </li>
          <li>
            <a href="#">vfx</a>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <h1>extras</h1>
        <ul>
          <li>
            <a href="https://www.youtube.com/channel/UCDH9jd3buX-cCzKfBoUlFiA">
              r-e-a-l.<span className="i">i</span>t/tv
            </a>
          </li>
          <li>
            <a href="https://r-e-a-l.it/capture/news">news</a>
          </li>
          <li>
            <a href="https://r-e-a-l.it/capture/contact">contact</a>
          </li>
          <li>
            <a href="https://www.r-e-a-l.it/my/home">
              my.r-e-a-l.<span className="i">i</span>t
            </a>
          </li>
          <li>
            <a href="http://download.r-e-a-l.software/">downloads</a>
          </li>
        </ul>
      </div>
      {/* <div class="copyright">
        <small>&copy;2022 R-E-A-L.iT</small>
        <br />
        <small>
          Website by{" "}
          <a
            href="ezekiel.deblois.me"
            style="font-family: font-family: 'Source Sans Pro', sans-serif; color: #888;"
          >
            ezekiel j. deblois
          </a>
        </small>
      </div> */}
    </div>
  );
};

export default Footer;
