import React from "react";

class Industries extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    renderData: null
  };

  componentDidMount() {
    Promise.all([this.props.content]).then(res => {
      this.setState({ renderData: res[0] });
    });
  }

  render() {
    return (
      <>
        {this.state.renderData && (
          <div
            className="industry"
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img src={this.state.renderData.image} style={{ width: "100vw" }}></img>
            <div style={{ width: "80vw" }}>
              <h1>{this.state.renderData.title}</h1>
              <p>{this.state.renderData.blurb}</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Industries;
