import React from "react";
import "./SubHeader.css";

class SubHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const listItems = this.props.array.map((i) => (
      <a key={i} href={"#" + String(i).replaceAll(" ", "")}>
        <h3>{i}</h3>
      </a>
    ));
    return <div className="subheader">{listItems}</div>;
  }
}

export default SubHeader;
