import React from "react";
import SpecsItem from "./SpecsItem";
class Specs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 1,
      list: props.array,
    };
  }

  render() {
    const specs = this.state.list.map((i) => {
      return (
        <div
          className="specs-column"
          style={{ gridColumn: "span 1", listStyle: "none" }}
        >
          <h1>{i.section}</h1>
          <SpecsItem array={i.stats} />
        </div>
      );
    });
    return specs;
  }
}

export default Specs;
